import get from 'lodash.get';
import { navigate } from 'gatsby';
import { cleanedUrl } from '../constans/locations';

export const generateLinkPath = (patch, destination, item) => {
  const countryName = destination.name;
  const regionName = destination.region?.name;
  const cityName = destination.region?.city?.name;
  let links = `/${patch}/${countryName}`;

  if (cityName) {
    links += `/${regionName}/${cityName}/${item?.name || ''}`;
  } else if (regionName) {
    links += `/${regionName}/${item?.name || ''}`;
  }

  if (!cityName && !regionName) {
    links += `/${item?.name || ''}`;
  }

  return cleanedUrl(links);
};

export const generateActivitiesOrPropertiesLinks = (patch, details) => {
  const cityPart = cleanedUrl(`${get(details, 'urlDestination')
      || get(details, 'city.name')
      || get(details, 'region.name')
      || get(details, 'country.name')}`);
  const activityPart = cleanedUrl(`${get(details, 'urlName') || get(details, 'title')}`);

  return `/${patch}/${cityPart}/${activityPart}/`;
};

export const cardClick = (title, city, region, country, urlDestination, urlName, event, isActivity = false) => {
  const cityPart = cleanedUrl(`${urlDestination || city?.name || region?.name || country?.name}`);
  const propertyPart = cleanedUrl(`${urlName || title}`);
  event.preventDefault();
  if (event.ctrlKey) return window.open(`/${isActivity ? 'activities' : 'properties'}/${cityPart}/${propertyPart}/`, '_blank');
  navigate(`/${isActivity ? 'activities' : 'properties'}/${cityPart}/${propertyPart}/`);
};

export const getIds = (destination) => {
  const ids = {};
  if (destination.id) {
    ids.countryId = destination.id;
  }
  if (destination.region?.id) {
    ids.regionId = destination.region.id;
  }
  if (destination.region?.city?.id) {
    ids.cityId = destination.region.city.id;
  }
  if (destination.region?.city?.district?.id) {
    ids.cityId = destination.region.city.district.id;
  }
  return ids;
};

export const getLocationName = (destination) => {
  let { name } = destination;

  if (destination.region?.id) {
    name = destination.region.name;
  }
  if (destination.region?.city?.id) {
    name = destination.region.city.name;
  }
  if (destination.region?.city?.district?.id) {
    name = destination.region.city.district.name;
  }
  return name;
};

export const getFullLocationName = (storageDestination) => {
  const {
    country, region, city, district,
  } = storageDestination;
  const name = country?.name || '';
  if (district?.id) return district.name;
  if (city?.id) return city.name;
  if (region?.id) return region.name;

  return name;
};

export const generateLocationLinkPath = (destination, linkPrefix) => {
  const countryName = destination.name;
  const regionName = destination.region?.name;
  const cityName = destination.region?.city?.name;
  const districtName = destination.region?.city?.district?.name;

  let links = '';
  if (countryName) {
    links += `/${cleanedUrl(countryName)}`;
  }

  if (districtName) {
    links += `-${cleanedUrl(districtName)}/${linkPrefix}`;
    return links;
  }

  if (cityName) {
    links += `-${cleanedUrl(cityName)}/${linkPrefix}`;
    return links;
  }

  if (regionName) {
    links += `-${cleanedUrl(regionName)}/${linkPrefix}`;
    return links;
  }

  links += `/${linkPrefix}`;

  return links;
};
