import React from 'react';
import { Helmet } from 'react-helmet';
import moment from 'moment/moment';
import { DEFAULT_FORMAT } from '../../constans/formats';

export function StructuredData({ article, property }) {
  return (
    <Helmet title="Koralgo">
      {(article ? true : null) && (
      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'BlogPosting',
          headline: property.title,
          description: property.metaDescription,
          image: property?.photo?.file.link,
          datePublished: moment(property.createdAt).format(DEFAULT_FORMAT),
          dateModified: moment(property.updatedAt).format(DEFAULT_FORMAT),
        })}
      </script>
      )}
    </Helmet>
  );
}

export function SeoData({ urlPath }) {
  return (
    <Helmet>
      <link rel="canonical" href={`https://koralgo.com${urlPath}`} />
    </Helmet>
  );
}

export function StructuredDataActivity({ article, details }) {
  return (
    <Helmet title="Structured Data">
      {(article ? true : null) && (
      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'BlogPosting',
          headline: details.title,
          description: details.metaDescription,
          image: details?.activityReview?.photos[0]?.file?.link,
          datePublished: moment(details.createdAt).format(DEFAULT_FORMAT),
          dateModified: moment(details.updatedAt).format(DEFAULT_FORMAT),
        })}
      </script>
      )}
    </Helmet>
  );
}
